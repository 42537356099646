import React from 'react';
import { Grid } from '@mui/material';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import moment from 'moment';
import { useAppointmentContext } from 'src/context/AppointmentContext';
import { useThemeContext } from 'src/context/ThemeContext';
import { useGlobalContext } from 'src/context/GlobalContext';

const CalendarDates = () => {

    const { startingDate, setStartingDate } = useAppointmentContext();
    const { secondaryColor } = useThemeContext();
    const { isMobile } = useGlobalContext();

    const handleDateChange = (isForward) => {
        if (isForward) {
          setStartingDate(moment(startingDate).add(5, 'days'));
        } else {
          if(moment().isSameOrBefore(moment(startingDate).subtract(5, 'days'), 'days')) setStartingDate(moment(startingDate).subtract(5, 'days'));
        }
    }

    const headers = [];
    for(let i = 0; i < 5; i++) {
        headers.push(
            <Grid key={i} item xs={12/5} md={2} className='date-header' sx={{backgroundColor: i % 2 === 0 ? '#f4f4f4' : '#fff'}}>
                <div>{moment(startingDate).add(i, 'days').format('ddd')}</div>
                <div>{moment(startingDate).add(i, 'days').format('D MMM')}</div>
                {(isMobile && i === 0 && moment().isSameOrBefore(moment(startingDate).subtract(5, 'days'), 'days')) && 
                    <div className='calendar-nav' onClick={() => handleDateChange(false)} style={{backgroundColor: secondaryColor}}>
                        Back
                    </div>
                }
                {(isMobile && i === 4) && 
                    <div className='calendar-nav' onClick={() => handleDateChange(true)} style={{backgroundColor: secondaryColor}}>
                        Next
                    </div>
                }
            </Grid>
        )
    };


    return (
        <Grid container className='header-bar'>
            {!isMobile && <Grid item xs={1}>
                { moment().isSameOrBefore(moment(startingDate).subtract(5, 'days'), 'days') && 
                <div className='calendar-nav' onClick={() => handleDateChange(false)} style={{backgroundColor: secondaryColor}}>
                    <ArrowLeft />
                </div> }
            </Grid>}
                {headers}
            {!isMobile &&<Grid item xs={1}>
                <div className='calendar-nav' onClick={() => handleDateChange(true)} style={{backgroundColor: secondaryColor}}>
                    <ArrowRight />
                </div>
            </Grid>}
        </Grid>
    );
}

export default CalendarDates;