import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import Calender from 'src/components/Calendar';
import CalendarDates from './CalendarDates';
import { useGlobalContext } from 'src/context/GlobalContext';
import { useAppointmentContext } from 'src/context/AppointmentContext';
import { useThemeContext } from 'src/context/ThemeContext';
import { motion } from 'framer-motion';
import moment from 'moment';

const AppointmentList = () => {

  const { 
    branch, 
    practitioners, 
    selectedType, 
    currentPractitioners, 
    setCurrentPractitioners,
    setNumAvailablePractitioners,
    isMobile
  } = useGlobalContext();
  const { startingDate } = useAppointmentContext();
  const { secondaryColor } = useThemeContext();

  const [openBio, setOpenBio] = useState('');

  useEffect(() => {
  
    const timer = setTimeout(() => {
      initList();  
    }, 0); 
  
    return () => clearTimeout(timer);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [practitioners, selectedType, startingDate]);

  const initList = async() => {
    let numAvailablePractitioners = 0;
    practitioners.forEach(practitioner => {
      if(practitioner.availabilities.length > 0 && (!selectedType.providers || selectedType.providers.includes(practitioner.ID))) {
        numAvailablePractitioners++;
      }
      practitioner = branch.client.methods.checkPractitioner(practitioner, {ID: selectedType.ID, duration: selectedType.duration});
    });

    // Sort practitioners based on their first availabily after the starting date
    const sortedPractitioners = practitioners.sort((a, b) => {
      const aDate = a.availabilities.find(availability => moment(availability, 'YYYY-MM-DD HH:mm').isAfter(startingDate));
      const bDate = b.availabilities.find(availability => moment(availability, 'YYYY-MM-DD HH:mm').isAfter(startingDate));

      if (aDate && bDate) {
        return moment(aDate, 'YYYY-MM-DD HH:mm').diff(moment(bDate, 'YYYY-MM-DD HH:mm'), 'minutes');
      }
      return aDate ? -1 : bDate ? 1 : 0;
    });

    setNumAvailablePractitioners(numAvailablePractitioners);
    setCurrentPractitioners(sortedPractitioners);
  }

  return (
    <div className='appointment-list-wrapper'>
      {currentPractitioners.length === 0 && <div className='no-appointment'>No appointments available</div>}
      {currentPractitioners.map((practitioner, index) => {
        if (practitioner.availabilities.length > 0 && (!selectedType.providers || selectedType.providers.includes(practitioner.ID))) {  
          return (
            <Grid key={index} container sx={{borderBottom: !practitioner.isLast ? '1px solid #979797' : '', borderTop: index === 0 ? '1px solid #979797' : ''}}>
              <Grid item md={2} xs={4}>
                <div className='icon-wrapper'>
                  <img className='optometrist-image' alt="Practitioner" src={practitioner.imgSrc} onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = "/static/images/optometrist/Account.png";
                  }}>
                  </img>
                </div>
              </Grid>
              <Grid item md={4} xs={8}>
                <div className='practitioner-name'>{practitioner.firstName + ' ' + practitioner.lastName}</div>
                {(isMobile && openBio !== practitioner.firstName + ' ' + practitioner.lastName) ? 
                  <div 
                    className="practitioner-read-bio" 
                    style={{color: secondaryColor}} 
                    onClick={() => setOpenBio(practitioner.firstName + ' ' + practitioner.lastName)}
                  >
                    Read Bio
                  </div> 
                  : 
                  <motion.div 
                    animate={{ height: 'auto'}} 
                    initial={{ height: 0 }} 
                    transition={{ ease: "easeInOut", duration: 0.3 }} 
                    style={{ overflow: 'hidden'}}
                  >
                    <div className='practitioner-bio'>{practitioner.biography}</div>
                      {isMobile && <div className="practitioner-read-bio"
                        style={{color: secondaryColor}} 
                        onClick={() => setOpenBio('')}
                      >
                        Close
                      </div>}
                  </motion.div>
                }
              </Grid>
              <Grid item md={6} xs={12}>
                {isMobile && <CalendarDates />}
                <Calender practitioner={practitioner}/>
              </Grid>
            </Grid>
          )
        } else {
          return <div key={index}></div>
        }
      })}
    </div>
  )
}

export default AppointmentList;