import { OpticalCo } from "./clients/OpticalCo";
import { BIM } from "./clients/BIM";
import { Allsports } from "./clients/Allsports";

const clientsMap = {
    opticalco: OpticalCo,
    BIM: BIM,
    allsports: Allsports,
    // Add new clients here
};

export class Branch {
    constructor() {

        const isDev = process.env.NODE_ENV === 'development';
        const devDetails = {
            site: "BIM",
            id: "1",
        }

        this.address = "";
        this.phone = "";
        this.name = "";
        this.postcode = "";
        this.email = "";
        this.state = "";
        this.suburb = "";
        this.bulkbill = false;
        this.appointmentTypes = [];
        this.practioners = [];
        this.clientName = "";
        this.formFields = [];
        this.params = new URLSearchParams(window.location.search);

        this.clientName = isDev ? devDetails.site : this.params.get('site');
        if (clientsMap[this.clientName]) {
            this.client = new clientsMap[this.clientName]();
        } else {
            throw new Error(`Client ${this.clientName} not found`);
        }

        this.identifier = isDev ? devDetails.id : this.params.get('id');
        this.theme = this.client.theme();
        this.theme.customStyles += this.getGlobalCustomStyles();

    }

    async init() {
        let locationData = await this.client.methods.getLocationData(this.identifier);
        if(!locationData) return false;

        this.formFields = this.client.methods.getFormFields(); 
        this.name = locationData.name;
        this.address = locationData.address;
        this.phone = locationData.phone;
        this.postcode = locationData.postcode;
        this.email = locationData.email;
        this.state = locationData.state;
        this.bulkbill = locationData.bulkbill;
        this.suburb = locationData.suburb;

        // Get google analytics id
        let response = await this.apiRequest('session/getGoogleID');
        this.client.GAId = response.google_analytics_id;

        return true;
    }

    async apiRequest(endpoint) {
        return await fetch(`/nookal/${endpoint}?session_token=${process.env.NODE_ENV === 'development' ? process.env.NODE_ENV : this.params.get('session_token')}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
        .then(data => {
            return data;
        });
    }

    getGlobalCustomStyles() {
        return `
            .time-wrapper:hover { background-color: ${this.theme.secondary}; }
            .more-times { background-color: ${this.theme.secondary}; }
        `;
    }
}