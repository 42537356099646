import { nookal } from '../services/nookal.js';
import ReactGA from "react-ga4";

export class BIM {

    constructor() {
        this.methods = nookal;
        this.useCategory = true;
        this.GAId = "";
        this.includeBothExistingNew = [
            'Massage'
        ]
    }

    theme() {
        const colors = { 
            primary: '#1c1d28',
            secondary: '#e30032',
            secondarySelected: '#4b5054'
        }

        const customStyles = '';

        return {
            ...colors,
            customStyles
        };
    }

    bookingCompleteEvents() {
        if(!this.GAId) return;
        
        ReactGA.initialize(this.GAId);
        
        // Send GA4 Event
        ReactGA.event('generate_lead', {
            action: 'Booking Complete',
            label: 'Booking Complete',
            lead_type: "Booking Complete"
        });
    
        console.log("Booking created event sent", this.GAId);
    }

}